import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { DataContext } from "../../utils/dataContext/dataContext";
import { SelectedAlertsContext } from "../../utils/SelectedAlertContext";
import RoutesHistory from "../../components/routesHistory/routesHistory";
import AlertTable from "components/table/alertTable";
import SelectMultipleFilterInput from "../../components/dropdown/MultiSelectCheckbox";
import AddToActionCart from "../addToActionCart/AddToAction";
import alertDetailsConstants from "../../constants/alertDetails";
import { alertDetailAPI } from "../../api/demo";
import styles from "./alertDetails.module.css";
import { ListItem } from "@mui/material";
import { Tooltip, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ProgressBarChart from "../../components/progressBar/progressBar";


const AlertDetails = () => {
  const location = useLocation();
  const routesHistoryData = location?.state?.routesHistoryData;
  // const [alertDetailsData, setAlertDetailsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const { selectedAlerts, alertDetailsData, setAlertDetailsData, filteredData, setFilteredData } = useContext(SelectedAlertsContext);
  const [alertCounts, setAlertCounts] = useState({ P1: 0, P2: 0 });
  const [uniqueMaterialPlantCount, setUniqueMaterialPlantCount] = useState(0);

  const initialDropdownState = useMemo(() => ({
    "Alert Type": [],
    "Site Code": [],
    "Material ID": [],
    "Material Type": [],
    "Material Description": [],
    "Recommendation": [],
    "Week": [],
    "Alert Status": [],
    "Site Cluster": [],
    // "CBU": []
  }), []);

  const [getDropdown, setDropdown] = useState(initialDropdownState);
  const [selectedValues, setSelectedValues] = useState(initialDropdownState);

  /**
   * Fetch alert details data
   */
  const fetchData = useCallback(async () => {
    try {
      const data = await alertDetailAPI();
      setAlertDetailsData(data?.alertDetails || []);
      setFilteredData(data?.alertDetails || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching alert details:", error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!alertDetailsData) return;

    const updatedFilteredData = alertDetailsData.filter((item) =>
      Object.keys(selectedValues).every((filterKey) => {
        if (!selectedValues[filterKey].length) return true; // Skip filter if no values are selected

        if (filterKey === "Week") {
          const alertIdPrefix = item["Alert Id"].split("-").slice(0, 2).join("-");
          return selectedValues[filterKey].includes(alertIdPrefix);
        }

        if (filterKey === "Recommendation") {
          return selectedValues[filterKey].includes(item.isRecommended ? "Yes" : "No");
        }

        // General filter logic for other fields
        return selectedValues[filterKey].includes(item[filterKey]);
      })
    );

    setFilteredData(updatedFilteredData);
  }, [selectedValues, alertDetailsData]);


  useEffect(() => {
    if (!filteredData.length) return;

    const allOptions = Object.keys(initialDropdownState).reduce((acc, key) => {
      acc[key] = [
        ...new Set(filteredData.map((item) =>
          key === "Week"
            ? item["Alert Id"].split("-").slice(0, 2).join("-")
            : key === "Recommendation"
              ? item.isRecommended ? "Yes" : "No"
              : item[key]
        )),
      ];
      return acc;
    }, {});

    const sortedWeekOptions = [...allOptions.Week].sort((a, b) => {
      const [fyA, weekA] = a.split("-");
      const [fyB, weekB] = b.split("-");
      return fyA === fyB
        ? parseInt(weekA) - parseInt(weekB)
        : fyA.localeCompare(fyB);
    });
    allOptions.Week = sortedWeekOptions;

    const updatedDropdowns = Object.keys(initialDropdownState).reduce((acc, key) => {
      if (selectedValues[key]?.length) {
        acc[key] = getDropdown[key];
      } else {
        acc[key] = filteredData
          .filter((item) =>
            Object.keys(selectedValues).every((filterKey) => {
              if (!selectedValues[filterKey].length) return true;
              if (filterKey === "Week") {
                const alertIdPrefix = item["Alert Id"].split("-").slice(0, 2).join("-");
                return (
                  selectedValues[filterKey].includes(item[filterKey]) ||
                  selectedValues[filterKey].includes(alertIdPrefix)
                );
              }
              if (filterKey === "Recommendation") {
                return selectedValues[filterKey].includes(item.isRecommended ? "Yes" : "No");
              }
              return selectedValues[filterKey].includes(item[filterKey]);
            })
          )
          .map((item) =>
            key === "Week"
              ? item["Alert Id"].split("-").slice(0, 2).join("-")
              : key === "Recommendation"
                ? item.isRecommended ? "Yes" : "No"
                : item[key]
          )
          .filter((value, index, self) => self.indexOf(value) === index);
      }
      return acc;
    }, {});


    setDropdown(updatedDropdowns);
  }, [filteredData, selectedValues]);

  /**
   * Handle dropdown value change
   */
  const handleMultiSelectChange = (label, values) => {
    setSelectedValues((prev) => ({
      ...prev,
      [label]: values
    }));
  };

  /**
   * Reset filters
   */
  const resetFilters = () => {
    const restObj = {
      "Alert Type": [],
      "Site Code": [],
      "Material ID": [],
      "Material Type": [],
      "Material Description": [],
      "Recommendation": [],
      "Week": [],
      "Alert Status": [],
      "Site Cluster": [],
    };
    setSelectedValues(restObj);
    setResetKey((prev) => prev + 1);
  };

  /**
   * Handle add to cart click
   */
  const handleAddToCartClick = () => {
      setIsDrawerOpen(true);
  };

  const alertStatusCounts = useMemo(() => {
    const openAlerts = filteredData.filter((alert) => alert["Alert Status"] === "Open").length;
    const closedAlerts = filteredData.filter((alert) => alert["Alert Status"] === "Closed").length;
    return [
      { name: 'Open', value: openAlerts },
      { name: 'Closed', value: closedAlerts },
    ];
  }, [filteredData]);

  useEffect(() => {
    if (!filteredData.length) return;

    const counts = filteredData.reduce(
      (acc, item) => {
        const alertType = item["Alert Type"];
        if (alertType === "P1 Alert") acc.P1 += 1;
        if (alertType === "P2 Alert") acc.P2 += 1;
        return acc;
      },
      { P1: 0, P2: 0 }
    );

    setAlertCounts(counts);
  }, [filteredData]);

  useEffect(() => {
    if (!filteredData.length) return;

    const uniqueCombinations = new Set(
      filteredData.map(item => `${item["Material ID"]}-${item["Site Code"]}`)
    );
    setUniqueMaterialPlantCount(uniqueCombinations.size);
  }, [filteredData]);

  return (
    <DataContext.Provider value={{ routesHistoryData }}>
      <div className={styles.alertDetailsContentContainer}>
        <div className={styles.columnStack}>
          <div className={`${styles.rowStack} ${styles.routesHistoryContainer}`}>
            <RoutesHistory content={routesHistoryData} />
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button disabled={!Object.values(selectedValues).some((val) => val.length > 0)} onClick={resetFilters} className={styles.actionBtn}>
                {alertDetailsConstants.resetFilterTitle}
              </Button>
              <Button disabled={!selectedAlerts.some((alert) => alert["selectedCard"].length > 0)} onClick={handleAddToCartClick} className={styles.actionBtn}>
                {alertDetailsConstants.addToActionCartTitle}
                <ShoppingCartOutlinedIcon className={styles.shopIcon} />
              </Button>
            </div>
          </div>
          <div className={styles.insights} style={{ display: 'flex', gap: '10px' }}>
            <div className={styles.subKPITile} style={{ backgroundColor: 'rgba(31, 54, 199, 0.75)' }}>
              <span style={{ fontSize: "16px", display: "flex", alignItems: 'center' }}>
                Material Impacted <Tooltip title="Unique combinations of Material ID and Plant Code">
                  <IconButton size="small" style={{ color: 'white' }}>
                    <InfoOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </span>
              <span style={{ fontSize: "20px", fontWeight: 600 }}>
                {uniqueMaterialPlantCount}
              </span>
            </div>
            <div className={styles.subKPITile} style={{ backgroundColor: 'rgba(31, 54, 199, 0.75)' }}>
              <span style={{ fontSize: "16px", display: "flex", alignItems: 'center' }}>Total P1 Alert
                <Tooltip title="Alert raised when there is a direct demand impact ( Demand vs Opening Stock)">
                  <IconButton size="small" style={{ color: 'white' }}>
                    <InfoOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </span>
              <span style={{ fontSize: "20px", fontWeight: 600 }}>{" "}{alertCounts.P1}</span>
            </div>
            <div className={styles.subKPITile} style={{ backgroundColor: 'rgba(31, 54, 199, 0.75)' }}>
              <span style={{ fontSize: "16px", display: "flex", alignItems: 'center' }}>Total P2 Alert
                <Tooltip title="Alert raised when there is an impact on safety norm ( Safety Norm vs Opening Stock)">
                  <IconButton size="small" style={{ color: 'white' }}>
                    <InfoOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </span>
              <span style={{ fontSize: "20px", fontWeight: 600 }}>{" "}{alertCounts.P2}</span>
            </div>
            <div className={styles.subKPProgressBar} style={{ backgroundColor: ' rgba(31, 54, 199, 0.75)' }}>
              <span style={{ fontSize: "16px", display: "flex", alignItems: 'center' }}>Alert Status</span>
              <ProgressBarChart data={alertStatusCounts} />
            </div>
          </div>
        </div>
        <div className={styles.alertDetails}>
          {loading ? (
            <div className={styles.loaderContainer}><CircularProgress /></div>
          ) : (
            <>
              <div className={styles.filters}>
                {Object.keys(getDropdown).map((key) => (
                  <ListItem key={key} className={styles.listCustomClass}>
                    <SelectMultipleFilterInput
                      key={`${key}-${resetKey}`}
                      label={key}
                      options={getDropdown[key]}
                      selected={selectedValues[key]}
                      onChange={(values) => handleMultiSelectChange(key, values)}
                    />
                  </ListItem>
                ))}
              </div>
              <AlertTable data={filteredData} />
            </>
          )}
        </div>
      </div>
      {/* </div> */}
      <AddToActionCart anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </DataContext.Provider>
  );
};

export default AlertDetails;
