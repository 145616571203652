import React, { useState, useEffect } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
    LabelList
} from 'recharts';
import { Typography } from '@mui/material';
import styles from './verticalBarGraph.module.css';
import { convertToLakhs } from '../../utils/convertValueIntoLakhs';


const HorizontalBarGraph = ({ data }) => {

    const processedData = data.map(item => ({
        ...item,
        Delta: Math.max(item.Delta, 0),
    }));

    const sortedData = [...processedData].sort((a, b) => b.Quantity_planed - a.Quantity_planed);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const legendStyle = {
        fontSize: windowWidth <= 1500 ? '10px' : '12px',
        paddingBottom: windowWidth <= 1500 ? '10px' : '15px',
        color: 'black',
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0]?.payload || {};
            const tooltipFields = [
                { label: 'FG Description', key: 'FGDescription', defaultValue: 'N/A' },
                { label: 'Revenue Impacted', key: 'RevenueAtRisk_CBU', format: convertToLakhs, defaultValue: 0 },

            ];
            return (
                <div className={styles.customTooltip}>
                    {tooltipFields.map(({ label, key, format, defaultValue }) => {
                        const value = data[key] ?? defaultValue;
                        const displayValue = format ? format(value) : value;
                        return (
                            <p key={key} className={styles.tooltipItem} style={{ margin: '2px' }}>
                                <span style={{ fontWeight: 600 }}>{label}</span>: {displayValue}
                            </p>
                        );
                    })}
                </div>
            );
        }
        return null;
    };

    const recordsCount = sortedData?.length || 0;
    const barHeight = 50;
    const chartHeight = recordsCount * barHeight + 50;

    return (
        <>
            {sortedData && sortedData[0].message ? (
                <Typography className={styles.displayText}>
                   {sortedData[0].message}
                </Typography>
            ) : (
                <ResponsiveContainer width="90%" height={chartHeight} className={styles.barChart}>
                    <BarChart
                        data={sortedData}
                        layout="vertical"
                        margin={{
                            top: 10,
                            right: 70,
                            left: 70,
                            bottom: 10,
                        }}
                    >
                        <>
                            <XAxis
                                type="number"
                                dataKey="Quantity_planed"
                                tickLine={false}
                                className={styles.BarLabel}
                            >
                                <Label
                                    value="Quantity (EA)"
                                    position="insideBottom"
                                    offset={-5}
                                    style={{ fontSize: '10px', fontWeight: 'bold' }}
                                />
                            </XAxis>
                            <YAxis
                                dataKey="FG_Number"
                                type="category"
                                className={styles.BarLabel}
                                tick={{ textAnchor: 'end' }}
                            >
                                <Label
                                    value="Impacted FG Number"
                                    angle={-90}
                                    position="insideLeft"
                                    dx={-45}
                                    style={{
                                        textAnchor: 'middle',
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                    }}
                                />
                            </YAxis>
                            <Tooltip
                                content={<CustomTooltip />}
                                wrapperStyle={{
                                    fontSize: windowWidth <= 1500 ? '10px' : '12px',
                                    padding: windowWidth <= 1500 ? '4px' : '6px',
                                }}
                            />
                            <Legend
                                verticalAlign="top"
                                align="right"
                                wrapperStyle={legendStyle}
                                formatter={(value) => <span style={{ color: 'black' }}>{value}</span>}
                            />
                            <Bar
                                dataKey="Quantity_planed"
                                name="Planned FG Quantity"
                                fill="#82ca9d"
                                barSize={15}
                            ><LabelList
                                    dataKey="Quantity_planed"
                                    position="right"
                                    className={styles.labelList}
                                    formatter={(value) => value?.toFixed(1)}
                                /></Bar>
                            <Bar
                                dataKey="Delta"
                                name="Quantity Impacted"
                                fill="rgb(255, 100, 124)"
                                barSize={15}
                            ><LabelList
                                    dataKey="Delta"
                                    position="right"
                                    className={styles.labelList}
                                    formatter={(value) => value?.toFixed(1)}
                                /></Bar>
                        </>
                    </BarChart>
                </ResponsiveContainer>
            )
            }
        </>

    );
};

export default HorizontalBarGraph;
