import React from "react";
import { convertToCrores } from "../../../utils/convertValueIntoLakhs";
import styles from "./styles/infoPaneLeft.module.css";

const InfoPaneLeft = ({ info }) => {
  const infoPaneLeftData = info.filter(
    ({ label }) =>
      label === "Revenue Potential" || label === "Material Impacted"
  );

  return (
    <div className={styles.infoPaneLeftContents}>
      {infoPaneLeftData.map((infoTileValue, index) => {
        const tileStyle = {
          backgroundColor:
            infoTileValue.label === "Revenue Potential"
              ? "#F20C041A"
              : infoTileValue.label === "Material Impacted"
                ? "#0EFF051A"
                : "transparent",
        };

        return (
          <div key={index} className={styles.infoTile} style={tileStyle}>
            <span className={styles.infoTitle}>{infoTileValue.label}</span>
            <span className={styles.infoTileMainContent}>
              {infoTileValue.label === "Revenue Potential"
                ? `₹ ${convertToCrores(infoTileValue.value)}`
                : `${infoTileValue.value}`}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default InfoPaneLeft;
