import headerHulLogo from "../assets/images/header/headerHulLogo.svg";
import samarthrHulLogo from "../assets/images/samarth-logo.png";

const headerConstants = {
  userName: "Punit",
  userRole: "Nerve Center Orchestrator",
  headerTitle: process.env.REACT_APP_ENV === 'production' ? "Samarth" : "Samarth-" + process.env.REACT_APP_ENV,
  headerUserProfileGreetingMessage: "Hello ",
  headerHulLogo: {
    name: "HUL",
    path: headerHulLogo,
  },
  samarthHulLogo: {
    name: "Samarth",
    path: samarthrHulLogo,
  },
};

export default headerConstants;
